import React from 'react';

const CardMembership = ({
	img,
	title,
	subtitle,
	iconClass = 'h-12 img--benefit'
}) => {
	return (
		<>
			<style jsx>
				{`
					.img--benefit {
						position: absolute;
						top: 15px;
						left: 15px;
					}
					.img--benefit-sos {
						position: absolute;
						top: 30px;
						left: 11px;
					}
				`}
			</style>
			<div className="flex">
				<div>
					<div className="border border-pink-200 h-20 w-20 relative rounded-full bg-white">
						<img loading="lazy" src={img} className={iconClass} />
					</div>
				</div>
				<div className="flex flex-col align-center justify-center pl-3">
					<span className="font-bold text-lg text-purple-500">{title}</span>
					<span className="text-sm">{subtitle}</span>
				</div>
			</div>
		</>
	);
};

export default CardMembership;
