import React, { Fragment } from 'react';
import CardMembership from '../benefits/card-membership';
import DOMPurify from 'isomorphic-dompurify';
import BasicModal from '../BasicModal/BasicModal';
import ChillModalLaunchApp from '../forms/common/ChildModalLaunchApp';

const sanitizer = DOMPurify.sanitize;
const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const bgFamily = `${serverUrlImages}/membership/banner_VE.png`;
const apple = `${serverUrlImages}/icons/apple.svg`;
const android = `${serverUrlImages}/icons/google-play.svg`;
const android_app_link = process.env.REACT_APP_LINK_ANDROID;

const MembershipContent = (props) => {
	const [openModalLaunchApp, setOpenModalLaunchApp] = React.useState(false);

	const {
		formType,
		stepHome = false,
		showText,
		origin,
		contentValue,
		children,
		hash = false,
		ios_app_link,
		android_app_link,
		isAppleDevice = false
	} = props;

	return (
		<>
			<BasicModal
				open={openModalLaunchApp}
				onClose={() => setOpenModalLaunchApp(false)}
			>
				<ChillModalLaunchApp onClose={() => setOpenModalLaunchApp(false)} />
			</BasicModal>
			<div
				className={`md:content pb-16 ${
					formType === 'signing' && origin !== 'sos'
						? 'mt-10 md:mt-12 content'
						: ''
				} ${hash ? 'pt-10' : 'pt-32'}`}
			>
				<div
					className={`w-full flex flex-col-reverse lg:max-w-6xl lg:mx-auto ${
						origin !== 'sos' || stepHome === 'motivo'
							? 'flex-wrap lg:mx-auto md:flex-row'
							: ''
					}`}
				>
					<div
						className={`w-full md:w-1/2 px-10 ${
							origin === 'login' || origin === 'join' ? 'hidden md:block' : ''
						} ${origin !== 'sos' ? 'md:pl-16 md:pr-4 lg:pr-10 lg:pl-0' : ''} ${
							showText ? 'mx-auto' : 'hidden'
						} `}
					>
						{origin !== 'sos' || contentValue?.origin !== 'sos' ? (
							<span className="text-5xl text-purple-500 font-bold block leading-tight mb-8">
								<div
									className="font-poppins"
									dangerouslySetInnerHTML={{
										__html: sanitizer(contentValue?.title)
									}}
								/>
							</span>
						) : (
							<div className="text-3xl text-purple-500 font-bold block leading-tight my-auto">
								<div
									className="font-poppins"
									dangerouslySetInnerHTML={{
										__html: sanitizer(contentValue?.title)
									}}
								/>
							</div>
						)}
						<span className="text-2xl text-purple-500 font-bold block leading-tight">
							<div
								className="font-poppins"
								dangerouslySetInnerHTML={{
									__html: sanitizer(contentValue?.afterTitle)
								}}
							/>
						</span>
						<span className="block text-purple-500 text-lg leading-relaxed my-8">
							<div
								dangerouslySetInnerHTML={{
									__html: sanitizer(contentValue?.subtitle)
								}}
							/>
						</span>

						<div className="flex flex-wrap">
							{contentValue?.benefits?.map((benefit) => (
								<Fragment key={benefit.description}>
									{!benefit.button ? (
										<div className="w-full p-3">
											<CardMembership
												img={benefit.icon}
												iconClass={benefit.iconClass}
												title={
													<div
														dangerouslySetInnerHTML={{
															__html: sanitizer(benefit.header)
														}}
													/>
												}
												subtitle={
													<div
														dangerouslySetInnerHTML={{
															__html: sanitizer(benefit.description)
														}}
													/>
												}
											/>
										</div>
									) : (
										<>
											{/* <div
											className="hidden md:block md:w-5/12 w-8/17 mr-3"
											dangerouslySetInnerHTML={{
												__html: sanitizer(benefit.button)
											}}
										/> */}
											{/* TODO FIX WHEN IOS LINK */}
											{!isAppleDevice && (
												<>
													<div className="hidden md:block md:w-5/12 w-8/17 mr-3">
														<ButtonDownloadApp
															alt={'icon apple'}
															icon={apple}
															txt1={'iPhone'}
															txt2={'App Store'}
															href={ios_app_link}
														/>
													</div>

													<div className="hidden md:block md:w-5/12 w-8/17 mr-3">
														<ButtonDownloadApp
															alt={'Google Play'}
															icon={android}
															txt1={'Android'}
															txt2={'Google Play'}
															href={android_app_link}
														/>
													</div>
												</>
											)}
										</>
									)}
								</Fragment>
							))}
						</div>
						{contentValue?.info && (
							<div
								className="my-6 md:mb-0"
								dangerouslySetInnerHTML={{
									__html: sanitizer(contentValue?.info)
								}}
							/>
						)}
					</div>
					<div
						className={`px-10 w-full mb-10 md:mb-0 ${
							stepHome === 'motivo' || origin !== 'sos'
								? 'md:w-1/2 md:mx-auto md:pr-16 md:pl-4 lg:pl-10 lg:pr-0'
								: origin !== 'sos' && stepHome !== 'motivo'
								? ' '
								: 'lg:max-w-3xl md:mx-auto px-20'
						}`}
					>
						{children}
					</div>
				</div>
			</div>
			<div className="overflow-hidden flex items-center sm:h-68 md:h-76">
				<img
					loading="lazy"
					src={bgFamily}
					alt="enfermera atendiendo a una persona mayor"
					className="w-full h-auto sm:mt-12 md:mt-20 lg:mt-48"
				/>
			</div>
		</>
	);
};

const ButtonDownloadApp = ({ href, icon, alt, txt1, txt2 }) => {
	return (
		<a
			alt={alt}
			className="membership-app-button bg-purple-500 text-white w-full flex justify-center items-center mr-5"
			target="_blank"
			href={href}
			rel="noreferrer"
		>
			<img loading="lazy" src={icon} alt="apple icon" className="w-5 mr-2" />
			<span className="sm:hidden font-medium text-white">{txt1}</span>
			<span className="hidden sm:inline-block font-medium text-white">
				{txt2}
			</span>
		</a>
	);
};

export default MembershipContent;
