/* eslint-disable quotes */
const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const apple = `${serverUrlImages}/icons/apple.svg`;
const android = `${serverUrlImages}/icons/google-play.svg`;

export const registryContent = (
	origin,
	ally_name = 'Un aliado',
	{ ios_app_link, android_app_link }
) => {
	let data = {};
	switch (origin) {
		case 'login':
			// LOGIN
			data = {
				origin: 'login',
				title: '<b class="font-poppins">Welcome back!</b>',
				subtitle:
					"<b class='text-purple-500 font-normal text-lg'>Don't forget to download our mobile App!</b>",
				info: '',
				benefits: [
					{
						header: `<a target="_blank" href="${ios_app_link}"> <b class="font-poppins">App Store</b> </a>`,
						description: '     ',
						button: `
              <a class="membership-app-button bg-purple-500 text-white w-full flex justify-center items-center mr-5" target="_blank" href="${ios_app_link}">
                <img loading="lazy" src="${apple}" alt="apple icon" class="w-5 mr-2"/>
                <span class="sm:hidden font-medium text-white">iPhone</span>
                <span class="hidden sm:inline-block font-medium text-white">App Store</span>
              </a>
            `
					}
					// 		{
					// 			header: `<a target="_blank" href="${android_app_link}"> <b class="font-poppins">Google Play Store</b> </a>`,
					// 			description: '  ',
					// 			button: `
					//   <a class="membership-app-button bg-purple-500 text-white w-full flex justify-center items-center" target="_blank" href="${android_app_link}">
					//     <img loading="lazy" src="${android}" alt="android icon" class="w-5 mr-2"/>
					//     <span class="sm:hidden font-medium text-white">Android</span>
					//     <span class="hidden sm:inline-block font-medium text-white">Google Play</span>
					//   </a>
					// `
					// 		}
				]
			};
			break;
		case 'sos':
			// DESDE SOS DEL HOME
			data = {
				origin: 'sos',
				title:
					'<b class="font-poppins text-pink-500">asistensi</b> <b class="font-poppins">S.O.S</b>, the best free telemedicine platform for medical emergencies 24/7',
				subtitle: `
        <p class="text-1xl leading-relaxed mt-14"> With <b class="font-poppins text-pink-500">assitensi S.O.S.</b> you can make a digital medical consultation right now and talk, of if necessary, with a <b>Venezuelan doctor.</b></p>
        <p class="text-1xl leading-relaxed mt-6"><b class="font-poppins text-pink-500">Start your query now!</b></p>
        <p class="text-1xl leading-relaxed mt-6"> It is totally free, you will receive immediate medical guidance.</p>
        <p class="text-1xl mt-6"><b>No matter the reason for the consultation,</b> we are here to take care of you.</p>
        `
			};
			break;
		case 'sos-signup':
			// DESDE SOS DEL HOME
			data = {
				origin: 'sos',
				title:
					'<h6 class="text-center mx-auto">Before giving you the results we need you to join <b class="font-poppins text-pink-500">asistensi</b> <h6>'
			};
			break;
		case 'sos-signin':
			// DESDE SOS DEL HOME
			data = {
				origin: 'sos',
				title:
					'<h6 class="text-center mx-auto">Before giving you the results we need you to log in to <b class="font-poppins text-pink-500">asistensi</b> <h6>'
			};
			break;
		case 'affiliate':
			// DIRECTO DESDE AFILIARSE AHORA
			data = {
				origin: 'affiliate',
				title:
					'You are just one step away from contracting your plan <b class="text-pink-500 font-poppins">asistensi</b>',
				afterTitle:
					'Create your account and join <b class="text-pink-500 font-poppins">asistensi</b>',
				subtitle:
					'To continue with the contracting process of your plan <b class="text-pink-500">asistensi</b> you need to create your account. At the end of the affiliation process you will be able to:',
				info: 'And much more',
				benefits: [
					{
						header: 'Add and/or delete beneficiaries',
						description:
							'You can affiliate all your loved ones or eliminate beneficiaries. Too easy',
						icon: `${serverUrlImages}/icons/icono_agregar_remover.png`
					},
					{
						header: 'Change plans <b class="text-pink-500">support</b>',
						description:
							'You will be able to increase coverage and benefits very easily when you require it.',
						icon: `${serverUrlImages}/icons/icono_cambiar_planes.png`
					},
					{
						header: 'Request services',
						description:
							'With a single click you can request the service your beneficiaries need and immediately one of our doctors will guide them through the process.',
						icon: `${serverUrlImages}/icons/icono_solicitar_servicio.png`
					},
					{
						header: 'Review your medical history',
						description:
							'You can keep a record of the services requested and used, diagnoses, recipes, etc.',
						icon: `${serverUrlImages}/icons/icono_revisar.png`
					}
				]
			};
			break;
		case 'quotation':
			// DESDE LA COTIZACION,
			data = {
				origin: 'quotation',
				title: 'Create your account to continue',
				afterTitle:
					'Join <b class="text-pink-500 font-poppins">support</b> completely free',
				subtitle:
					'By creating your account you will receive <b>immediately and completely free</b> the following benefits:',
				info: 'To continue with the quotation process for your plan <b class="text-pink-500">asistensi</b> it is necessary to create your account. By creating your account you will receive <b>immediately and completely free</b> the following benefits:',
				benefits: [
					{
						header:
							'Contracting plans <b class="text-pink-500">faster asistensi</b>',
						description:
							'Contracting plans <b class="text-pink-500">faster asistensi</b>',
						icon: `${serverUrlImages}/images/contrataciones.png`
					},
					{
						header: 'Other benefits',
						description:
							'You will enjoy many benefits such as exclusive discounts that we will notify you soon.',
						icon: `${serverUrlImages}/icons/benefits-page/assignment-ind.svg`
					}
				]
			};
			break;
		case 'quick-ally':
			// AFILIACION RAPIDA
			data = {
				origin: 'quick-ally',
				title: `${ally_name} has invited you to join <b class="text-pink-500 font-bold">support</b>`,
				afterTitle: '',
				subtitle:
					'To continue with the quotation process for your plan <b class="text-pink-500">asistensi</b> it is necessary to create your account. By creating your account you will receive immediately and completely free of charge the following benefits:',
				info: '',
				benefits: [
					{
						header:
							'Contracting plans <b class="text-pink-500">faster asistensi</b>',
						description:
							'Facilitate your process of contracting plans <b class="text-pink-500">asistensi</b> to take care of the health of your loved ones in your country of origin. It is necessary to join to hire our plans.',
						icon: `${serverUrlImages}/images/contrataciones.png`
					},
					{
						header: 'Other benefits',
						description:
							'You will enjoy many benefits such as exclusive discounts that we will notify you soon.',
						icon: `${serverUrlImages}/icons/benefits-page/assignment-ind.svg`
					}
				]
			};
			break;
		case 'admin':
		case 'join':
			// DESDE "UNETE" DEL NAVBAR , VENGAN INVITACIÓN DESDE ADMIN
			data = {
				origin: 'login',
				title: '<b class="font-poppins">Welcome back!</b>',
				subtitle:
					"<b class='text-purple-500 font-normal text-lg'>Don't forget to download our mobile App!</b>",
				info: '',
				benefits: [
					{
						header: `<a target="_blank" href="${ios_app_link}"> <b class="font-poppins">App Store</b> </a>`,
						description: '     ',
						button: `
              <a class="membership-app-button bg-purple-500 text-white w-full flex justify-center items-center mr-5" target="_blank" href="${ios_app_link}">
                <img loading="lazy" src="${serverUrlImages}/icons/apple.svg" alt="icono de apple" class="w-5 mr-2"/>
                <span class="sm:hidden font-medium text-white">iPhone</span>
                <span class="hidden sm:inline-block font-medium text-white">App Store</span>
              </a>
            `
					}
					// 		{
					// 			header: `<a target="_blank" href="${android_app_link}"> <b class="font-poppins">Google Play Store</b> </a>`,
					// 			description: '  ',
					// 			button: `
					//   <a class="membership-app-button bg-purple-500 text-white w-full flex justify-center items-center" target="_blank" href="${android_app_link}">
					//     <img loading="lazy" src="${serverUrlImages}/icons/google-play.svg" alt="icono de apple" class="w-5 mr-2"/>
					//     <span class="sm:hidden font-medium text-white">Android</span>
					//     <span class="hidden sm:inline-block font-medium text-white">Google Play</span>
					//   </a>
					// `
					// 		}
				]
			};
			break;
		case 'ally':
			// DESDE INVITACION DE ALIADO
			data = {
				origin: 'ally',
				title: `${ally_name} has invited you to join <b class="text-pink-500 font-bold">support</b>`,
				afterTitle: 'You are just one step away from hiring your plan',
				subtitle:
					'To continue with the hiring process of your plan <b class="text-pink-500">asistensi</b> it is necessary to create your account. At the end of the affiliation process you will be able to:',
				info: 'And much more',
				benefits: [
					{
						header: 'Add and/or remove beneficiaries',
						description:
							'You can affiliate all your loved ones or eliminate beneficiaries. Too easy!',
						icon: `${serverUrlImages}/icons/icono_agregar_remover.png`
					},
					{
						header: 'Change plans <b class="text-pink-500">support</b>',
						description:
							'You will be able to increase coverage and benefits very easily when you require it.',
						icon: `${serverUrlImages}/icons/icono_cambiar_planes.png`
					},
					{
						header: 'Request services',
						description:
							'With a single CLICK you can request the service your beneficiaries need and immediately one of our doctors will guide them through the process.',
						icon: `${serverUrlImages}/icons/icono_solicitar_servicio.png`
					},
					{
						header: 'Review your medical history',
						description:
							'You will be able to carry out the registration of the services requested and used, diagnoses, prescriptions, etc.',
						icon: `${serverUrlImages}/icons/icono_revisar.png`
					}
				],
				anotherInfo:
					"And if you haven't decided yet, join <b class='text-pink-500'>support</b> completely free and receive immediately:",
				moreBenefits: [
					'<b class="text-pink-500">asistensi</b> S.O.S',
					// 'News in health',
					'Contracting plans <b class="text-pink-500">faster asistensi</b>',
					'Other benefits'
				]
			};
			break;
		default:
			break;
	}

	return data;
};

export const profileContent = (
	origin,
	name,
	coverage = 'PHP300,000 coverage per HMO health plan per year',
	ios_app_link,
	android_app_link
) => {
	let data = {};
	switch (origin) {
		case 'join':
			data = {
				origin: 'join',
				banner: {
					style: '',
					classes:
						'rounded bg-pink-500 p-10 flex flex-col md:flex-row justify-between items-center',
					title:
						'Protect your loved ones with <b class="text-white">asistensi</b>!',
					description: `Purchase an <b class="text-white">asistensi</b> <b>plan</b> and look after your loved ones against day-to-day ailments, acute illnesses, and medical emergencies up to PHP300,000 coverage per HMO health plan per year.`,
					labelBtn: 'Sign Up Now!',
					cta: 'afiliar-con-evento',
					apps: false,
					background: false
				},
				header: {
					title:
						'Welcome to <b class="text-pink-500 font-poppins">support</b>!',
					subtitle:
						'From now on you can enjoy all the benefits of being part of <b class="text-pink-500">asistensi</b>.',
					info: 'We invite you to protect the health of your loved ones by contracting <b class="text-pink-500">asistensi</b> the best insurance for medical emergencies and immediate medical care.',
					labelBtn: 'Sign Up Now!',
					cta: 'afiliar-con-evento'
				},
				benefitsTable: [
					{
						icon: `${serverUrlImages}/images/contrataciones.png`,
						title: 'Subscribe to <b class="text-pink-500">asistensi</b>',
						description:
							'The most extensive and affordable health coverage including medical emergencies',
						labelBtn: 'SUBSCRIBE NOW',
						cta: 'afiliar-con-evento'
					}
				]
			};
			break;
		case 'sos':
			data = {
				origin: 'sos',
				banner: {
					style: '',
					classes:
						'rounded bg-pink-500 p-10 flex flex-col md:flex-row justify-between items-center',
					title:
						'You can now use <b class="text-white">asistensi</b> <b class="text-purple-500">S.O.S</b>!',
					description:
						'The best free digital Telemedicine platform available 24/7',
					labelBtn: 'Try it today!',
					cta: 'so',
					background: false
				},
				header: {
					title:
						'Welcome to <b class="text-pink-500 font-poppins">support</b>!',
					subtitle:
						'From now on you can enjoy <b class="text-pink-500">asistensi</b> <b class="text-purple-500">S.O.S</b>, the best free and unlimited digital telemedicine platform , available 24/7, with which you can rule out pathologies by completing a simple questionnaire and, if you need it, be contacted by a doctor for personalized guidance by phone or video call.',
					info: 'We also invite you to protect the health of your loved ones by contracting <b class="text-pink-500">asistensi</b> the best insurance for medical emergencies and immediate medical care.',
					labelBtn: 'Sign Up Now!',
					cta: 'afiliar-con-evento'
				},
				benefitsTable: [
					{
						icon: `${serverUrlImages}/images/contrataciones.png`,
						title: 'Subscribe to <b class="text-pink-500">asistensi</b>',
						description:
							'The most extensive and affordable health coverage including medical emergencies',
						labelBtn: 'SUBSCRIBE NOW',
						cta: 'afiliar-con-evento'
					}
				]
			};
			break;
		case 'affiliate':
			data = {
				banner: {
					style: '',
					classes:
						'rounded bg-pink-500 p-10 flex flex-col md:flex-row justify-between items-center',
					title:
						'Protect your loved ones with <b class="text-white">asistensi</b>!',
					description: `Purchase an <b class="text-white">asistensi</b> <b>plan</b> and look after your loved ones against day-to-day ailments, acute illnesses, and medical emergencies up to PHP300,000 coverage per HMO health plan per year.`,
					cta: '',
					apps: true,
					background: false
				},
				header: {
					title: `Welcome <b class="text-pink-500 font-poppins capitalize">${name}</b>!`,
					subtitle:
						'Here in your account you can manage your affiliations, access payment information, make changes to your profile and much more!',
					info: 'All the options can be found in the vertical menu on the left side of your screen.',
					labelBtn: 'Add recipients!',
					cta: 'afiliar'
				},
				benefitsTable: [
					// {
					// 	icon: `${serverUrlImages}/icons/benefits-page/telemedicina.svg`,
					// 	title:
					// 		'Your loved ones are protected with <b class="text-pink-500">asistensi</b>',
					// 	description:
					// 		'The best insurance for medical emergencies with immediate medical attention',
					// 	labelBtn: 'HOW DOES IT WORK?',
					// 	cta: 'dialog',
					// 	titleDialog: 'How does it work?',
					// 	descriptionDialog: `Through our mobile App available at <a target="_blank" href="${ios_app_link}"> <b class="font-poppins">App Store</b> </a> o <a target="_blank" href="${android_app_link}"> <b class="font-poppins">Google Play Store</b></a> you can request medical services from your loved ones. And for you, who hires <b class="text-pink-500">asistensi</b>, you enjoy unlimited video consultations so that you can also take care of your health.`
					// }
				]
			};
			break;
		case 'beneficiary':
			data = {
				banner: {
					style: '',
					classes:
						'rounded bg-pink-500 p-10 flex flex-col md:flex-row justify-between items-center',
					title:
						'Protect your loved ones with <b class="text-white">asistensi</b>!',
					description: `Purchase an <b class="text-white">asistensi</b> <b>plan</b> and look after your loved ones against day-to-day ailments, acute illnesses, and medical emergencies up to PHP300,000 coverage per HMO health plan per year.`,
					labelBtn: false,
					cta: '',
					apps: true,
					background: false
				},
				header: {
					title:
						'You are protected with <b class="text-pink-500 font-poppins">support</b>!',
					subtitle:
						'Here in your account you can access your affiliation information, request our services with just one click, review your medical history, and much more!',
					labelBtn: 'My Profile',
					cta: 'profile'
				},
				benefitsTable: [
					{
						icon: `${serverUrlImages}/icons/benefits-page/telemedicina.svg`,
						title:
							'Your loved ones are protected with <b class="text-pink-500">asistensi</b>',
						description:
							'The best insurance for medical emergencies with immediate medical attention',
						labelBtn: 'REQUEST SERVICE'
					}
				]
			};
			break;
		case 'allyBK':
			data = {
				banner: {
					style: '',
					classes:
						'rounded bg-pink-500 p-10 flex flex-col md:flex-row justify-between items-center',
					title:
						'¡En <b class="font-poppins">asistensi</b> we take care of your health',
					description:
						'Now you have <b class="font-poppins">asistensi</b> S.O.S, the best free digital telemedicine platform available 24/7. Try it today!',
					labelBtn: 'Check Now',
					cta: 'sos',
					apps: false,
					background: false
				},
				header: {
					title:
						'Welcome to your Affiliate <b class="text-pink-500 font-poppins">asistensi</b> Module!',
					subtitle:
						'We have refreshed the look and feel of your Affiliate Module to make your navigation much more user-friendly. Now you will get all the sections related to your management in the left menu and in the top menu, you will find the website and your profile areas.',
					info: 'Remember! Your Affiliate Module has all the tools you need to enhance your management. From a single place you will be able to: quote, affiliate, ask for support from a Big Broder, track your referral payments, your accumulated points in the Big Family, and much more!'
				}
			};
			break;
		case 'ally':
			data = {
				banner: {
					style: '',
					classes:
						'rounded p-14 flex flex-col md:flex-row justify-between items-center bg-no-repeat bg-cover xs:bg-left md:bg-center',
					title:
						'<b class="font-poppins text-pink-500">THE NEW</b> <b class="font-poppins text-purple-500 text-2xl">payment scheme</b>',
					description:
						'<span class="font-poppins text-purple-500 text-2xl">to generate more revenue</span>',
					labelBtn: 'More info',
					cta: 'schema',
					apps: false,
					background: true
				},
				header: {
					title:
						'Welcome to your Affiliate <b class="text-pink-500 font-poppins">asistensi</b> Module!',
					subtitle:
						'We have refreshed the look and feel of your Affiliate Module to make your navigation much more user-friendly. Now you will get all the sections related to your management in the left menu and in the top menu, you will find the website and your profile areas.',
					info: 'Remember! Your Affiliate Module has all the tools you need to enhance your management. From a single place you will be able to: quote, affiliate, ask for support from a Big Broder, track your referral payments, your accumulated points in the Big Family, and much more!'
				}
			};
			break;
		default:
			break;
	}

	return data;
};
